<div class="tw-px-10 tw-py-6">
  <h2 class="tw-text-lg">
    My Contriburions for
    <span class="tw-font-bold" [ngStyle]="{ color: (resource$ | async).color }">
      {{ (resource$ | async).resource_name }}</span
    >
  </h2>

  <!-- Number Cards -->
  <div
    *ngIf="mainNumbers$ | async as numbers"
    class="tw-flex tw-flex-row tw-gap-2 tw-mt-4 tw-justify-stretch tw-flex-wrap"
  >
    <div *ngFor="let entry of numbers" class="tw-p-1 tw-grow">
      <number-card
        [value]="entry.value"
        [label]="entry.label"
        [iconSrc]="entry.iconSrc"
      ></number-card>
    </div>
  </div>

  <div
    class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-start tw-mt-2 tw-gap-2"
  >
    <div class="lg:tw-w-1/2 2xl:tw-w-2/3">
      <div class="tw-p-1">
        <div class="tw-flex tw-flex-col tw-gap-4">
          <div class="tw-flex-1">
            <div
              class="tw-bg-white tw-p-2 tw-rounded tw-border tw-border-gray-300"
            >
              <span
                class="tw-block tw-w-full tw-text-gray-700 tw-font-bold tw-text-lg tw-font-dm-sans tw-px-3 tw-pt-1"
              >
                Badges in Progress
              </span>

              <ng-container
                *ngIf="terms$ | async as userTerms; else emptyStateTemplate"
              >
                <ng-container
                  *ngIf="
                    progressFilter$ | async as filter;
                    else emptyStateTemplate
                  "
                >
                  <my-badges-details
                    [terms]="userTerms"
                    [filter]="filter"
                  ></my-badges-details>
                </ng-container>
              </ng-container>

              <ng-template #emptyStateTemplate>
                <empty-state
                  src="assets/svg/bad2ge_check_dis.svg"
                  type="in progress badges"
                ></empty-state>
              </ng-template>
            </div>
          </div>

          <div class="tw-flex-1">
            <div
              class="tw-bg-white tw-p-2 tw-rounded tw-border tw-border-gray-300"
            >
              <span
                class="tw-block tw-w-full tw-text-gray-700 tw-font-bold tw-text-lg tw-font-dm-sans tw-px-3 tw-pt-1"
              >
                Earned Badges
              </span>

              <ng-container
                *ngIf="terms$ | async as userTerms; else emptyStateTemplate2"
              >
                <ng-container
                  *ngIf="
                    achievedFilter$ | async as filter;
                    else emptyStateTemplate2
                  "
                >
                  <my-badges-details
                    [terms]="userTerms"
                    [filter]="filter"
                  ></my-badges-details>
                </ng-container>
              </ng-container>

              <ng-template #emptyStateTemplate2>
                <empty-state
                  src="assets/svg/badge_check.svg"
                  type="earned badges"
                ></empty-state>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lg:tw-w-1/2 2xl:tw-w-1/3">
      <div class="tw-p-1">
        <div
          class="tw-w-full tw-bg-white tw-flex tw-p-2 tw-border tw-border-gray-300 tw-flex-col"
          *ngIf="userActivities$ | async as userActivities"
        >
          <h3
            class="tw-block tw-w-full tw-text-gray-700 tw-font-bold tw-text-lg tw-font-dm-sans tw-px-3 tw-pt-1"
          >
            Detailed Activity Statistics
          </h3>
          <doughnut-pie-chart
            [activities]="userActivities"
          ></doughnut-pie-chart>
        </div>
      </div>
    </div>
  </div>
</div>
